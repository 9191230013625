/* src/components/ProductList.css */
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Обеспечивает равномерное распределение карточек */
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .product-list {
    justify-content: space-between; /* Центрирование карточек и равномерное распределение на мобильных */
    padding: 0 10px; /* Уменьшаем отступы на мобильных */
  }
}