/* src/components/About.css */
.about-container {
    padding: 30px;
    /* background-color: #f7f9fc; */
    /* border-radius: 12px; */
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); */
    margin-bottom: 30px;
    line-height: 1.6;
    color: #333;
    /* max-width: 800px; */
    margin: 0 auto; /* Центрирование контейнера */
  }
  
  .about-container h1 {
    font-size: 28px;
    font-weight: 700;
    color: #0056b3;
    margin-bottom: 25px;
  }
  
  .about-intro,
  .about-conclusion {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .about-list {
    margin-bottom: 25px;
    padding-left: 20px;
  }
  
  .about-list li {
    margin-bottom: 12px;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  
  .about-icon {
    margin-right: 10px;
    font-size: 30px;
    color: #007bff; /* Цвет иконок */
  }
  
  .about-list li:hover {
    color: #007bff; /* Подсветка при наведении */
  }
  
  /* Мобильная адаптация */
  @media (max-width: 768px) {
    .about-container {
      padding: 15px;
      box-shadow: none;
      border-radius: 8px;
    }
  
    .about-container h1 {
      font-size: 22px;
    }
  
    .about-list li {
      font-size: 16px;
    }
  
    .about-icon {
      font-size: 18px;
    }
  
    .about-intro,
    .about-conclusion {
      font-size: 16px;
    }
  }