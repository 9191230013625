/* src/components/Banner.css */
.banner {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin: 20px 0;
  }
  
  .banner__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .banner__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(51, 43, 43, 0.5); /* Полупрозрачный черный фон для лучшей читаемости текста */
    padding: 20px;
  }
  
  .banner__title {
    font-size: 36px;
    margin: 0;
  }
  
  .banner__subtitle {
    font-size: 18px;
    margin: 10px 0 0;
    text-align: center;
  }
  
  /* Мобильная адаптация */
  @media (max-width: 768px) {
    .banner__title {
      font-size: 24px;
    }
  
    .banner__subtitle {
      font-size: 14px;
    }
    .banner__overlay {
        height: 69%;
    }
  }