/* src/components/Header.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.header {
  font-family: 'Montserrat', sans-serif;
  background-color: #007bff;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Добавляем тень */
  position: sticky;
  top: 0;
  z-index: 1000; /* Убедитесь, что хедер всегда на виду */
}

.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}

.header__logo {
  font-size: 28px;
  font-weight: 600;
}

.header__logo a {
  color: white;
  text-decoration: none;
}

.header__logo a:hover {
  color: #ffc107;
}

.header__actions {
  display: flex;
  align-items: center;
}

.header__contact {
  background-color: #daefef; /* Золотой цвет кнопки */
  color: #007bff; /* Синий цвет текста */
  padding: 10px 20px; /* Размер кнопки */
  border: none;
  border-radius: 30px; /* Скругленные края для кнопки */
  cursor: pointer;
  font-weight: 600;
  font-size: 16px; /* Размер шрифта */
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Тень для кнопки */
}

.header__contact:hover {
  background-color: #fd2828; /* Темнее при наведении */
  color: white;
}

.header__nav {
  background-color: #0056b3;
  padding: 10px 0;
  border-radius: 0 0 8px 8px; /* Скругление только снизу */
}

.header__nav-list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.header__nav-item {
  margin: 0 20px;
  transition: transform 0.3s ease; /* Добавляем эффект при наведении */
}

.header__nav-item:hover {
  transform: scale(1.1); /* Легкое увеличение */
}

.header__nav-item a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.header__nav-item a:hover {
  color: #ffc107;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .header__top {
    flex-direction: column;
    align-items: flex-start;
  }

  .header__actions {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .header__contact {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 8px;
  }

  .header__nav-list {
    flex-direction: row; /* Элементы меню будут выстраиваться в строку */
    justify-content: center; /* Центрируем элементы */
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .header__nav-item {
    margin: 0 10px; /* Отступы между элементами меню */
    padding: 5px 10px;
    border-radius: 8px;
  }

  .header__nav-item a {
    color: white;
    text-decoration: none;
    font-weight: 600;
  }

  .header__nav-item a:hover {
    color: #ffc107; /* Цвет текста при наведении */
  }
}