/* src/components/ProductDetail.css */
.product-detail-container {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background-color: #fff;
  margin: 20px auto;
}

.product-detail-image {
  flex: 1;
  margin-right: 20px;
  text-align: center;
}

.product-detail-image img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
}

.product-detail-placeholder {
  font-size: 80px; /* Увеличенная шестеренка */
  color: #007bff;
}

.product-detail-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-detail-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.product-detail-description-container {
  max-height: 300px; /* Ограничение по высоте для больших описаний */
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;
}

.product-detail-description {
  font-size: 18px;
  line-height: 1.6;
}

.product-detail-get-price {
  display: inline-block;
  padding: 15px 0;
  background-color: #28a745;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-size: 18px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.product-detail-get-price:hover {
  background-color: #218838;
}

.product-detail__not-found {
  text-align: center;
  font-size: 24px;
  color: #d9534f;
  margin-top: 20px;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .product-detail-container {
    flex-direction: column;
    align-items: center;
  }

  .product-detail-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .product-detail-info {
    align-items: center;
    text-align: center;
  }

  .product-detail-title {
    font-size: 24px;
  }

  .product-detail-description-container {
    max-height: 150px; /* Уменьшаем высоту для мобильных */
  }

  .product-detail-description {
    font-size: 16px;
  }

  .product-detail-get-price {
    font-size: 16px;
    padding: 12px 0;
    width: 100%;
  }
}