/* src/components/Footer.css */
.footer {
  background-color: #007bff; /* Фон в стиле интернет-магазина */
  color: white;
  padding: 30px;
  text-align: center;
  font-size: 14px;
  border-radius: 8px;
}

.footer__links {
  margin-bottom: 20px;
}

.footer__links a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-weight: 600;
}

.footer__links a:hover {
  color: #ffc107;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.footer__map {
  flex: 1;
  margin-right: 20px;
  min-width: 280px;
}

.footer__map iframe {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  border: none;
}

.footer__info {
  flex: 2;
  text-align: left;
  font-size: 14px;
  line-height: 1.6;
  color: white;
}

.footer__info p {
  margin: 8px 0;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.footer__icon {
  margin-right: 10px;
  color: #ffc107;
}

.footer__copyright {
  font-size: 12px;
  border-top: 1px solid #0056b3;
  padding-top: 10px;
}

/* Добавляем разделение для заголовков региональных контактов */
.footer__info h4 {
  margin-top: 20px;
  font-weight: 600;
  font-size: 1rem;
  color: #ffc107;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .footer__content {
      flex-direction: column;
      align-items: flex-start;
  }

  .footer__map {
      display: none; /* Скрываем карту на мобильных устройствах */
  }

  .footer__info {
      text-align: left;
  }

  .footer__links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 15px;
  }

  .footer__links a {
      margin: 0 10px;
      font-size: 16px;
  }

  .footer__copyright {
      font-size: 12px;
  }
}