/* src/components/ProductCard.css */

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: calc(25% - 20px); /* Четыре карточки в ряд с промежутками */
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  opacity: 0; /* Изначально карточки невидимы */
  transform: translateY(20px); /* Изначально сдвинуты вниз */
  animation: fadeInUp 0.5s forwards; /* Применяем анимацию всплытия */
}

.product-card:nth-child(1) {
  animation-delay: 0.1s;
}
.product-card:nth-child(2) {
  animation-delay: 0.2s;
}
.product-card:nth-child(3) {
  animation-delay: 0.3s;
}
.product-card:nth-child(4) {
  animation-delay: 0.4s;
}
.product-card:nth-child(5) {
  animation-delay: 0.5s;
}
.product-card:nth-child(6) {
  animation-delay: 0.6s;
}
.product-card:nth-child(7) {
  animation-delay: 0.7s;
}
.product-card:nth-child(8) {
  animation-delay: 0.8s;
}
.product-card:nth-child(9) {
  animation-delay: 0.9s;
}
.product-card:nth-child(10) {
  animation-delay: 1.0s;
}
.product-card:nth-child(11) {
  animation-delay: 1.1s;
}
.product-card:nth-child(12) {
  animation-delay: 1.2s;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.product-card__image {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.product-card__image img {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
}

.product-card__title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-top: 10px;
  white-space: normal; /* Позволяет тексту переноситься на новую строку */
  overflow: visible; /* Убираем обрезание текста */
}

.product-card__price {
  font-size: 16px;
  font-weight: 600;
  color: #007bff;
  text-align: center;
  margin-top: 10px;
}

/* Анимация всплытия */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .product-card {
    width: calc(50% - 20px); /* Две карточки в ряд на мобильных */
    margin: 10px;
  }

  .product-card__title {
    font-size: 14px; /* Уменьшение размера шрифта на мобильных устройствах */
  }
}