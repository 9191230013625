/* src/components/Contact.css */
.contact-container {
  padding: 30px;
  color: #333;
  margin: 0 auto;
}

.contact-container h1 {
  font-size: 28px;
  font-weight: 700;
  color: #0056b3;
  margin-bottom: 25px;
}

.contact-info p {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.6;
}

.contact-icon {
  margin-right: 10px;
  color: #007bff;
}

.contact-map {
  margin-top: 30px;
  border-radius: 12px;
  overflow: hidden;
}

.contact-section {
  margin-top: 20px;
}

.contact-section h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .contact-container {
    padding: 15px;
    box-shadow: none;
    border-radius: 8px;
  }

  .contact-container h1 {
    font-size: 22px;
  }

  .contact-info p {
    font-size: 16px;
  }

  .contact-icon {
    font-size: 16px;
  }

  .contact-section h2 {
    font-size: 18px;
  }
}