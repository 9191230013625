/* Общие стили для блока с категориями */
.categories {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Заголовок блока категорий */
.categories__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: #2c3e50;
  text-transform: uppercase;
}

/* Кнопка-бургер для мобильной версии */
.categories__burger {
  display: none;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.categories__burger:hover {
  background-color: #2980b9;
}

/* Список категорий */
.categories__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Пространство между элементами */
}

/* Элементы категорий */
.category {
  background-color: #007bff;
  color: white;
  padding: 15px;
  border-radius: 10px;
  margin: 10px;
  flex-basis: calc(25% - 20px); /* Четыре элемента в ряд */
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.category:hover {
  background-color: #0056b3;
  transform: translateY(-5px); /* Плавный эффект подъема при наведении */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
  /* Кнопка-бургер видна на мобильных */
  .categories__burger {
    display: block;
  }

  /* Скрываем список категорий по умолчанию на мобильных */
  .categories__list {
    display: none;
    flex-direction: column;
    gap: 5px;
  }

  /* Когда меню открыто, показываем список */
  .categories__list--open {
    display: flex;
  }

  /* Категории на мобильных занимают всю ширину */
  .category {
    flex-basis: 100%;
    padding: 15px;
  }
}

/* Увеличение иконок в категориях для мобильных устройств */
.category__icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .category__icon {
    font-size: 1.5rem;
  }
}