/* src/components/Pagination.css */
.pagination-nav {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  overflow-x: auto;
  white-space: nowrap;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  cursor: pointer;
  min-width: 40px;
  text-align: center;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-item.dots {
  display: flex;
  align-items: center;
  padding: 8px 0;
  color: #777;
  font-size: 18px;
}