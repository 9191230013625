/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f0f4f8;
  color: #333;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1600px;
  margin: 0 auto;
  /* padding: 20px; */
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.app-content {
  flex: 1;
}

footer {
  background-color: #0056b3;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin-top: auto;
}

/* Убираем margin и padding у всех элементов */
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/* Базовые стили для текста */
h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: #333;
}

p {
  /* line-height: 1.6; */
  /* margin-bottom: 15px; */
  /* color: #555; */
}

/* Утонченные стили для ссылок */
a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}

/* Мобильная адаптация */
@media (max-width: 768px) {
  .app {
    padding: 10px;
    max-width: 100%;
    border-radius: 0;
  }
}